.review-form-wrapper{
  background: $blue;
  padding: 25px 44px 37px 44px;

  @include respond-down(medium){
    padding: 25px 30px 37px 30px;

  }

  h2{
    font-weight: $bold;
    color: #ffffff;
    text-align: center;
    margin-bottom: 25px;
  }
  .form-group{

    .form-row{
      display: flex;
      margin: 0 -11px;
      margin-bottom: 26px;

      @include respond-down(small){
        display: block;
        margin: 0px;
      }

      &:last-child{
        margin-bottom: 0px;
      }

      .form-input{
        width: 50%;
        padding: 0 11px;
        color: #ffffff;


        @include respond-down(small){
          width: 100%;
          margin-bottom: 20px;
        }

        .form-field{
          margin-bottom: 0px;

          input, textarea {
            font-family: "ProximaN",sans-serif;
          }
          textarea{
            padding: 17px 27px 17px 27px;
            resize: vertical;
            min-height: 52px;
          }

          &.file, &.logo{
            position: relative;

            input[type=file]{
              opacity: 0;
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
            }

            label{
              background: transparent url("../svg/select-file.svg") 100% 50% no-repeat;
              min-height: 40px;
              font-size: 16px;
              color: #ffffff;
              display: flex;
              align-items: center;
              cursor: pointer;

              // @include respond-down(medium){
              //   background-position: 60% 50%;
              // }

              // @include respond-down(middle){
              //   background-position: 0% 50%;
              //   padding-left: 50px;
              // }
            }


          }


        }
      }

      .buttons-row{
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        align-items: center;

        @include respond-down(medium){
          display: block;
        }

        button{
          width: 170px;
          height: 50px;

          @include respond-down(medium){
            width: 100%;
            margin-bottom: 10px;
          }
        }
      }
      .form-policy {
        margin-top: 20px;
      }

    }
  }
}
