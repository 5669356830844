.read-also {
    // .read-also__wrap
    &__wrap {
        position: relative;
        margin-bottom: 60px;
        &:before, &:after {
            content: '';
            position: absolute;
            top: 15px;
            height: 300px;
            background: $blue;
        }
        &:before {
            width: 10px;
            left: -10px;
        }
        &:after {
            width: 14px;
            right: -14px;
        }
        @include respond-up(medium) {
            margin-bottom: 45px;
            &:before, &:after {
                top: unset;
                height: 15px;
                left: 27px;
            }
            &:before {
                width: 230px;
                top: -15px;
            }
            &:after {
                width: 230px;
                bottom: -15px;
            }
        }
        @include respond-up(middle) {
            margin-bottom: 80px;
            &:before, &:after {
                left: 39px;
            }
            &:before {
                width: 330px;
            }
            &:after {
                width: 330px;
            }
        }
    }
    // .read-also__row
    &__row {
        display: flex;
        flex-wrap: wrap;
        @include respond-up(medium) {
            flex-wrap: nowrap;
        }
    }
    // .read-also__item
    &__item {
        flex: 1 1 100%;
        &_text {
            position: relative;
            padding: 20px 18px 24px;
            background-color: #ffffff;
            &:after {
                content: '';
                position: absolute;
                bottom: -10px;
                left: -8px;
                height: 10px;
                width: 100%;
                padding-right: 19px;
                background: $orange;
            }
        }
        @include respond-up(medium) {
            &_img {
                flex: 0 1 auto;
            }
            &_text {
                flex: 1 1 auto;
                padding: 24px 28px 31px;
                &:after {
                    height: 100%;
                    width: 10px;
                    right: -10px;
                    left: unset;
                    top: -15px;
                    padding: 0px 0px 30px 0px;
                }
            }
        }
        @include respond-up(middle) {
            &_text {
                padding: 37px 40px 48px;    
            }
        }
    }
    // .read-also__img
    &__img {
        position: relative;
        min-height: 200px;
        height: 100%;
        width: 100%;
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        @include respond-up(medium) {
            width: 230px;
        }
        @include respond-up(middle) {
            width: 330px;
        }
    }
    // .read-also__h3
    &__h3 {
        margin-bottom: 22px;
        font-family: $proximaNova;
        font-size: 18px;
        font-weight: 700;
        line-height: 140%;
        color: $orange;
        @include respond-up(medium) {
            font-size: 20px;
        }
        @include respond-up(middle) {
            font-size: 24px;
        }
    }
    // .read-also__ul
    &__ul {
        *:last-child {
            margin-bottom: 0;
        }
    }
    // .read-also__li
    &__li {
        margin-bottom: 15px;
    }
    // .read-also__link
    &__link {
        font-family: $proximaNova;
        font-size: 14px;
        line-height: 140%;
        text-decoration: underline;
        color: #000;
        transition: $default-transition;
        &:hover {
            color: $orange;
            text-decoration: underline;
        }
    }
}
