.working-banner {
  position: fixed;
  width: 100%;
  height: 50px;
  top: 0;
  right: 0;
  left: 0;
  z-index: 201;

  &__content {
    display: flex;
    height: 54px;
    background-color: #ffffff;
    border-bottom: 1px solid #001E4D;

    a {
      font-size: 20px;
      height: 28px;
      line-height: 41px;
      padding-left: 53px;
      margin: auto;
      background: url("../svg/ic_working.svg") left center no-repeat;

      @include respond-down(small) {
        font-size: 16px;
        line-height: 44px;
      }
    }
  }

  &__popup {
    visibility: hidden;
    position: absolute;
    top: calc(100% + 3px);
    left: 50%;
    opacity: 0;
    transition: 0.5s;
    transform: translateX(-50%);
    max-width: 514px;
    background-color: #ffffff;

    .border {
      padding: 20px;
      border: 1px solid #001E4D;

      p {
        font-size: 18px;
        margin-bottom: 25px;

        @include respond-down(small) {
          font-size: 15px;
          line-height: 18px;
        }
      }
    }
  }

  &__button {
    text-align: center;

    .button {
      font-size: 14px;
      font-weight: normal;
      padding: 11px 48px;
    }
  }
  &:hover {
    .working-banner__popup {
      visibility: visible;
      opacity: 1;
    }
  }
}