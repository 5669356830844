.actions-holder{
  margin: 30px 0px 0px 0px;
  .action{
    background: #ffffff;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 20px 35px;
    border: 2px solid $blue;
    align-items: center;
    @include respond-down(small){
      padding: 12px 10px;
      display: block;
    }

    .left-image{
      @include respond-down(small){
        text-align: center;
        img{
          max-height: 200px;
        }
      }
    }
    .middle-description{
      margin: 0 20px;
      @include respond-down(small){
        margin: 0 0 15px;
      }
      .title{
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 30px;

        @include respond-down(small){
          font-size: 15px;
          margin: 15px 0px;
        }
      }
      ul{
        li{
          position: relative;
          padding-left: 20px;
          margin-bottom: 12px;
          &:before{
            content: '-';
            color: $orange;
            font-size: 50px;
            line-height: 14px;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
    .right-link{
      text-align: center;
      .button{
        padding: 20px 30px;
        white-space: nowrap;
        width: 100%;
      }
    }
  }
}

.action-page{
  .products{
    margin:  30px 0px;
  }
  h2{
    margin-bottom: 20px;
    text-align: center;
  }

  ul{
    li{
      position: relative;
      padding-left: 20px;
      margin-bottom: 12px;
      &:before{
        content: '-';
        color: $orange;
        font-size: 50px;
        line-height: 14px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .content{
    margin: 30px 0px;
    font-size: 16px;
    *{
      font-size: 16px;
    }
  }
}
