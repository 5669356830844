.pagination-block{
  margin: 30px 0px;
  .pagination-list{
    text-align: center;
    li{
      display: inline-block;
      width: auto;

      &.active{
        a{
          background: transparent;
        }
      }
      a{
        padding: 10px 12px;
        font-size: 16px;
        background: $orange;
        border-radius: 5px;
      }
    }
  }
}
