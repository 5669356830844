@include respond(small) {
  [data-parent-change-orientation] {
    position: relative;

    .content-raw:not(.tables-full-width) {
      height: 300px;
      overflow: hidden;
    }

    .content-raw.tables-full-width{
      .chars-table-wrapper{
        position: relative;
        &+p{
          margin-top: 10px;
        }
        .chars-table-wrapper-overflow{
          height: 300px;
          overflow: hidden;
        }
      }
    }
    .change-orientation {
      position: absolute;
      left: -20px;
      right: -20px;
      top: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.9);
      line-height: 300px;
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      text-align: center;
      display: block;


      .holder {
        line-height: 1.1;
        vertical-align: middle;
        padding: 0 20px;
        display: inline-block;
      }
      .icon-holder {
        display: inline-block;
        width: 70px;

        .svg-icon {
          width: 100%;
          height: 70px;
        }
      }
      .title {
        display: block;
        font-size: 18px;
        text-align: center;
        margin-top: 20px;
      }
    }

  }
}

@include respond-up(small){
  .change-orientation{
    display: none;
  }
}
