// set height!
.map {
  width: 100%;
  height: 100%;

  .custom-marker {
    position: absolute;
    font-size: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000000;

    .placemark {
      object-fit: contain;
      display: inline-block;
      cursor: pointer;
    }

    &.animate {
      animation: forwards bounceInDownMap 400ms;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
      opacity: 1;
      $i: 0;
      @while $i < 10 {
        $i: $i+0.1;
        &.animation-delay-#{$i*1000} {
          animation-delay: #{$i}s;
        }
      }
    }

    &.open {

      .placemark-holder {
        .icon-placemark {
          transform: rotate(-180deg);
        }

        .marker-title {
          transform: translateY(5px);
        }

        &:hover {
          .marker-title {
            opacity: 0;
            visibility: hidden;
          }

          .icon-icon-x {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    .placemark-holder {
      width: 65px;
      height: 65px;
      transition: $default-transition;
      font-family: $font;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .icon-placemark {
        transition: $default-transition;
        font-family: $font;
        display: inline-block;
      }

      .marker-title {
        font-family: $font;
        color: $primary-color;
        font-weight: 800;
        font-size: 24px;
        position: absolute;
        left: 50%;
        margin-left: -8px;
        margin-top: 5px;
        z-index: 1;
      }

      .icon-icon-x {
        transform: translateY(5px);
        color: $primary-color;
        font-weight: 800;
        font-size: 18px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -9px;
        margin-top: -15px;
        z-index: 20;
        background: red;
        opacity: 0;
        visibility: hidden;
      }

    }


  }

  .infobox {
    transform: translateY(-18%) translateX(calc(50% + 80px));
    transition: visibility 180ms ease, opacity 180ms ease, margin 180ms ease;
    cursor: auto;
    position: absolute;
    background-color: #ffffff;
    margin-left: -140px;
    margin-top: -45px;
    opacity: 1;
    z-index: 9999999;
    pointer-events: auto;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.14);

    @include respond-down(small) {
      transform: translateY(-40%);
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 70px;
      left: -6px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 6px 7px 0;
      border-color: transparent #ffffff transparent transparent;

      @include respond-down(small) {
        content: none;
      }
    }

    .closer-info {
      position: absolute;
      right: 5px;
      top: 0px;
      font-size: 25px;
    }

    &.no-active {
      opacity: 0;
      visibility: hidden;
      margin-top: -20px;
    }

    .slider-holder {
      .slide {
        height: 133px;
        background-size: cover;
        background-position: 50% 50%;
      }

      object-fit: cover;
    }

    .map-point-info {
      position: relative;
    }

    .map-point-closer {
      position: absolute;
      display: block;
      top: 10px;
      right: 10px;
      width: 30px;
      height: 30px;
      background-size: 14px 13px;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 6.5L.5 0 7 6.5 13.5 0 7 6.5zm0 0L.5 13 7 6.5l6.5 6.5L7 6.5z' fill-rule='nonzero' stroke='%23969696' fill='none' stroke-linecap='square'/%3E%3C/svg%3E");
      text-indent: -9999px;
    }

    .info-holder {
      display: flex;
      flex-direction: column;
      padding: 30px 24px 26px 30px;
      font-size: 14px;
      color: #000000;
      line-height: 19px;

      .point-title {
        margin: 0 0 17px 0;
        font-weight: $bold;
        font-size: 18px;
        color: $black;
        line-height: 15px;
        text-transform: uppercase;
        width: 263px;
        display: block;
      }

      .point-text {
        margin: 0 0 19px 0;
        width: 263px;
        display: block;
      }

      .point-address {
        margin: 0 0 19px 0;
        width: 263px;
        display: block;
      }

      .point-phone {
        &, a {
          font-size: 14px;
          color: #000;
        }
      }

      .point-contact {
        margin: 0 0 17px 0;
      }


      .point-email {
        &, a {
          font-size: 14px;
          color: #000;
        }
      }

      .point-phone-wrap{
        display: flex;
      }
      .map-contact-title {
        display: inline-block;
        width: 50px;
        font-size: 14px;
        color: #767676;
        flex: 0 0 50px;
      }
    }

  }


}
