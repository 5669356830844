.certs-list {
  display: flex;
  margin: 0 -10.5px;
  justify-content: center;

  @include respond-down(small) {
    display: block;
    font-size: 0px;
  }

  .cert-item {
    max-width: 33.33%;
    flex: 1 1 auto;
    padding: 0 10px;


    @include respond-down(medium) {
      width: 50%;
      max-width: 50%;
    }

    @include respond-down(small) {
      width: 100%;
      max-width: 100%;
      vertical-align: top;
      &:last-child{
        margin-bottom: 0px;
      }
    }

    .cert-wrapper {
      background-color: #fff;
      padding: 17px 16px 20px 17px;
      height: 100%;

      @include respond-down(medium) {
        padding: 10px 10px 15px 10px;
      }

      @include respond-down(small) {
        padding: 12px 10px 17px 10px;
        width: 100%;
      }
    }


    a{
      display: block;

    }

    .cert-image{
      img{
        max-height: 530px;

        @include respond-down(small){
          max-height: none;
          width: 100%;
        }

      }
    }

    .cert-name{
      color: black;
      font-weight: $bold;
      font-size: 16px;
      text-transform: uppercase;
      line-height: 20px;
      margin-top: 20px;

      @include respond-down(medium){
        font-size: 14px;
      }

      @include respond-down(small){
        font-size: 12px;
      }
    }
  }
}
