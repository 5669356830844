.catalogue-menu{
  background: #fff;

  .header__menu-item {
    &.catalog-item {

      .link-wrapper {
        width: auto;
        span.icon {
          width: 50px;
        }
      }
      .svg-icon {
        width: 30px;
        height: 30px;
        background-size: 30px;
      }

      &:hover {
        .header__menu_sub {

          &.catalog {
            transform: translateY(100%);
            transition-delay: 0s;
            visibility: visible;

          }
        }
      }


      .header__menu_sub {

        &.catalog {
          transform: translateY(80%);
          transition: all 0.3s ease-in-out;
          transition-delay: 300ms;
          padding: 0px;
          width: 305px;
          white-space: normal;
          z-index: -1;
          pointer-events: auto;
          visibility: hidden;

          &:before{
            position: absolute;
            top: 0;
            content: '';
            background: transparent url("../svg/menu-shadow.svg") 0 0 no-repeat;
            z-index: -1;
            left: -29px;
            right: -32px;
            bottom: -50px;
            pointer-events: none;
            background-size: contain;
          }


          .catalog-menu {
            border-bottom: 1px solid #B5BECB;
            background: #fff;

            &.position-end {
              border-bottom: none;
            }

            &.children-count-2{
              position: relative;
            }
            &.children-count-3.position-5{
              position: relative;
            }

            &:hover{
              .root-dropper{
                opacity: 1;
                visibility: visible;
                transform: translateX(0%);
                pointer-events: auto;

              }
            }


            .root-catalog-link {
              display: flex;
              height: 54px;
              align-items: center;
              justify-content: space-between;

              .icon {
                width: 50px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;

                .icon-wrapper {
                  display: flex;
                  width: 35px;
                  height: 28px;
                  justify-content: center;
                  align-items: center;

                  &.vertical {
                    width: 28px;
                    height: 35px;
                  }

                  img {
                    object-fit: contain;
                  }
                }
              }

              .title {
                width: 200px;
                font-size: 14px;
              }

              .dropper {
                width: 50px;
                height: 54px;
                display: flex;
                align-items: center;
                transition: background-color 0.3s ease-in-out;

                .icon {
                  display: block;
                  transition: transform 0.3s ease-in-out;
                  text-align: center;

                  .regular{
                    transition: $default-transition;
                    width: 10px;
                    height: 6px;
                    background: transparent url("../svg/arrow-menu-top-blue.svg") 50% 50% no-repeat;
                    display: inline-block;
                  }
                }
              }

              &:hover{
                .dropper{
                  background: rgb(181, 190, 203);

                  .icon{
                    .regular{
                      transform: rotate(-90deg);
                      background: transparent url("../svg/arrow-white-menu.svg") 50% 50% no-repeat;
                    }
                  }
                }
              }

              &.view-all {
                padding-left: 20px;
                color: #ffffff;
                background-color: #001E4D;
                transition: background-color 0.3s ease-in-out;

                .dropper {
                  .icon {
                    display: block;
                    position: relative;
                    height: 100%;

                    .regular {
                      display: block;
                      position: absolute;
                      top: 46.5%;

                      transform: rotate(-90deg);
                      background: transparent url("../svg/arrow-white-menu.svg") 50% 50% no-repeat;

                      &:first-child {
                        left: 35%;
                      }

                      &:last-child {
                        left: 45%;
                      }
                    }
                  }
                }

                &:hover {
                  color: #001E4D;
                  background-color: #ffffff;

                  .dropper{
                    background: transparent;

                    .icon{

                      .regular{
                        transform: rotate(-90deg);
                        background: transparent url("../svg/arrow-menu-top-blue.svg") 50% 50% no-repeat;
                      }
                    }
                  }
                }
              }
            }

            .root-dropper {
              position: absolute;
              opacity: 0;
              left: 100%;
              z-index: -1;
              top: -3px;
              background: #FFFFFF;
              border-top: 3px solid #f5b142;
              transform: translateX(-200px);
              transition: all 0.3s;
              transition-delay: 300ms;
              pointer-events: none;


              &:before{
                position: absolute;
                height: 100%;
                top: 0px;
                bottom: 0px;
                left: -13px;
                content: '';
                background: transparent url("../svg/shadow-inner-menu.svg") 0 0 no-repeat;
                width: 28px;
              }

              .line-product{
                width: 100%;
                flex: 0 0 100%;
                display: flex;
                border-bottom: 1px solid #B5BECB;
                border-right: 1px solid #B5BECB;
                &.children-count-3,
                &.children-count-2,
                &.children-count-1 {
                  .inner-dropper-item{
                    &:nth-child(2){
                      border-right: 1px solid #B5BECB;
                    }
                  }
                }
                .inner-dropper-item{
                  width: 250px;
                  flex: 0 0 250px;
                  min-height: 210px;
                  padding: 20px 0px;
                  &:first-child{
                    border-right: 1px solid #B5BECB;
                  }
                  .inner-dropper-wrapper{
                    display: flex;
                    justify-content: center;
                    padding-bottom: 20px;

                    .link-level-2{
                      display: flex;
                      flex-direction: column;

                      .icon{
                        display: flex;
                        justify-content: center;
                        .icon-wrapper{
                          height: 150px;
                          width: 150px;
                          display: flex;
                          justify-content: center;
                          align-items: center;

                          img{
                            object-fit: contain;
                            max-height: 100%;
                          }
                        }
                      }

                      &:hover{
                        .tile{
                          .name{
                            border-bottom-color: #001E4D;
                          }
                        }
                      }

                      .tile{
                        text-align: center;
                        font-size: 14px;
                        color: #001E4D;
                        max-width: 175px;
                        margin-top: 20px;
                        .name{
                          transition: $default-transition;
                          border-bottom: 1px solid transparent;
                        }
                      }
                    }

                  }
                }

              }
            }
          }
        }
      }
    }
  }

}
.ru {
  .catalogue-menu {
    .header__menu-item {
      &.catalog-item {
        .header__menu_sub {
          &.catalog {
            .catalog-menu {
              &.children-count-2 {
                position: relative;

                &.position-5 {
                  position: static;

                  .root-dropper {
                    top: auto;
                    bottom: 1px;
                  }
                }

                &.position-6 {
                  position: static;

                  .root-dropper {
                    top: auto;
                    bottom: 1px;
                  }
                }
              }

              &.children-count-3 {
                &.position-2 {
                  position: relative;
                }

                &.position-5 {
                  position: static;

                  .root-dropper {
                    top: auto;
                    bottom: 0;
                  }
                }
              }

              &.children-count-4 {
                &.position-1 {
                  .root-dropper {
                    max-height: 494px;

                    .line-product {
                      max-height: 246px;
                    }
                  }
                }
              }

              .root-dropper {
                &.hover {
                  pointer-events: auto;
                }

                .line-product{
                  .inner-dropper-item{
                    min-height: 200px;

                    .inner-dropper-wrapper{
                      .link-level-2{
                        .icon{
                          .icon-wrapper{
                            height: 139px;
                            width: 140px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }


  }
  .catalog-menu-wrapper{
    .root-item{
      .inner-menu{
        .inner-dropper-item{

          &.depth-2{

            &.active+li:not(.active){
              .inner-dropper-item-name{
                background: #001E4D;
              }
            }


            .inner-menu{
              li.inner-dropper-item{
                a.inner-dropper-item-name{
                  &.active, &:hover{
                    background: #69727F;
                    border-bottom: none;
                  }
                }
              }
            }
          }


        }
      }

    }

  }

}




