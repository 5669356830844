.seo-article{
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 40px;
  @include respond-down(small){
    font-size: 13px;
    line-height: 1.3;
  }
  @include respond-up(middle){
    margin-bottom: 60px;
  }
  h2{
    font-weight: $bold;
    text-align: center;
    position: relative;
    margin-bottom: 50px;

    &:after{
      content: '';
      height: 2px;
      max-width: 90%;
      left: 50%;
      transform: translateX(-50%);
      width: 50px;
      background: #f5b142;
      position: absolute;
      bottom: -20px;
    }

  }

  ul{
    list-style: none;
    line-height: 1.4;

    li{
      &:before{
        content: '—';
        color: $orange;
      }
    }
  }

  a{
    text-decoration: underline;
    cursor: pointer;
    transition: $default-transition;
    &:hover{
      color: #ff9e00;
    }
  }


  // [data-short-long-text]{
  //   max-height: 100px;
  //   overflow: hidden;

  //   h2{
  //     font-size: 20px;
  //     margin: 20px 0px;
  //     text-align: left;
  //   }

  //   +.bottom-more{
  //     background: #ECEDEC;
  //     position: relative;
  //     height: 20px;
  //     z-index: 9;
  //     margin: 10px 0px;
  //   }

  //   +a{
  //     margin: 10px 0px;
  //     display: block;
  //   }
  // }


}
