.simplebar-scrollbar {
  background: $orange;
  box-shadow: 0 5px 9px rgba(206, 141, 34, 0.463853);
  border-radius: 7px;

  &:before {
    content: none;
  }
}

.simplebar-track {
  border-radius: 7px;
  background: #D8D8D8;
  @include respond-down(middle) {
    opacity: 0 !important;
    pointer-events: none !important;
  }
}
