.footer-map {
  height: 600px;
  @include respond-down(middle) {
    height: 600px;
  }
  @include respond-down(small) {
    height: 350px;
  }

  .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
    top: 250px !important;
    @include respond-down(middle) {
      top: 305px !important;
    }
    @include respond-down(small) {
      top: 360px !important;
    }
  }

  @at-root &__wrapper {
    position: relative;
    background: $white;

    .row > .column {
      position: relative;
    }

  }
  &__heading{
    text-align: center;
    margin-bottom: 20px;
    font-weight: $bold;
    font-size: 30px;
    line-height: 37px;

    @include respond(small){
      font-size: 22px;
      line-height: 25px;
    }
  }
}

.map {
  @at-root &__search-block {
    display: flex;
    padding: 0 29px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 110px;
    justify-content: space-between;
    align-items: center;
    background: $white;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.14);
    z-index: 10;

    @include respond-down(small) {
      flex-direction: column;
      height: initial;
      padding: 46px 20px 27px;
    }
  }
  @at-root &__list-items {
    display: flex;
    padding: 0 20px 0 0;
    align-items: center;
    height: 100%;
    @include respond-down(small) {
      padding: 0;
    }
  }
  @at-root &__list-item {
    display: flex;
    position: relative;
    align-items: center;
    height: 100%;
    font-weight: $bold;
    font-size: 14px;
    color: $black;
    text-align: center;
    @include respond-down(small) {
      position: relative;
    }

    &:after {
      content: "";
      opacity: 0;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: $orange;
      height: 3px;

      @include respond-down(small) {
        bottom: -8px;
      }
    }

    &:not(:last-child) {
      margin: 0 64px 0 0;
      @include respond-down(middle) {
        margin: 0 44px 0 0;
      }
    }

    &.active, &:hover {
      &:after {
        opacity: 1;
      }
    }
  }
  @at-root &__input-block {
    display: flex;
    height: 52px;
    align-items: center;
    width: calc(100% * (8 / 12));
    @include respond-down(middle) {
      justify-content: flex-end;
    }

    @include respond-down(small) {
      width: 100%;
      height: initial;
      margin: 25px 0 0 0;
      flex-direction: column;
      justify-content: center;
    }
  }
  @at-root &__select {
    flex: 1 1;
    height: 100%;
    position: relative;
    &:after{
      @include triangle(10px, 7px, 'down', #F5B142);
      content: '';
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
    @include respond(medium) {
      max-width: 252px;
    }

    @include respond-down(small) {
      height: 52px;
      width: 100%;
    }
    select{
      opacity: 1;
      padding: 0 27px;
      background: $white;
      font-size: 14px;
      color: #7C7C7C;
      letter-spacing: 0;
      border: 1px solid #F5B142;
      border-radius: 26px;
      -webkit-appearance: none;
      width: 100%;
      height: 100%;
    }


  }
  @at-root &__option {

  }
  @at-root &__search-button {
    max-width: 147px;
    margin: 0 0 0 34px;
    @include respond-down(middle) {
      max-width: 128px;
    }

    @include respond-down(small) {
      margin: 10px 0 0 0;
      max-width: initial;
      width: 100% !important;

    }

  }
}
