.sitemap {
    padding-top: 150px;
    ul {
        margin-bottom: 20px;
    }
    li {
        margin-left: 10px;
        ul {
            margin-left: 10px;
            li {
                margin-right: 10px;
                list-style: disc;
            }
        }
    }
    a {
        text-decoration: underline;
    }
}